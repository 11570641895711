*{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.content-wrapper{
    margin: 10px auto;
    max-width: 1000px;
}
.content-wrapper.line-details{
    height: 400px;
    display: flex;

}
.content-wrapper.line-details .line-detail-wrrapper{
    width: 50%;
    
}
.hidden{
    display: none;
}
.success-message{
    border: 1px solid green;
    background-color: #d0fdd0;
    padding: 10px;
    margin-bottom: 20px;
    color: green;
}
.notice-message, .warning-message{
    border: 1px solid rgb(148, 60, 1);
    background-color: #ffdeac;
    padding: 10px;
    margin-bottom: 20px;
    color: rgb(148, 60, 1);
}
.error-message{
    border: 1px solid rgb(255, 30, 0);
    background-color: #ffb6ac;
    padding: 10px;
    margin-bottom: 20px;
    color: rgb(148, 1, 1);
}
.dialog-title{
    background-color: #000;
    color: #fff;
}
div.content-wrapper .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
    white-space: normal;
    word-break: break-word;
}
div.content-wrapper .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell[data-field="recording"]{
  justify-content: center;
}
.MuiContainer-root.content-wrapper{
    height: 50vh;
    max-width: unset;
    position: relative;
}
.MuiContainer-root.content-wrapper.service{
    min-height: 50vh;
    height: unset;
}

.MuiDataGrid-cell[data-field="delete"] svg{
    cursor: pointer;
}
.MuiDataGrid-cell[data-field="delete"] svg.locked{
    cursor: unset;
}
.MuiDataGrid-cell[data-field="delete"] svg.locked path{
    fill: lightgray;
}

div.content-wrapper .MuiDataGrid-cellContent{
    white-space: normal;
    word-break: break-word;
}
div.content-wrapper div[data-field="number"] .MuiDataGrid-cellContent{
  direction: ltr;
}
div.content-wrapper div[data-field="lineNumber"] .MuiDataGrid-cellContent{
  direction: ltr;
}
div.content-wrapper div[data-field="dialToOrFrom"] .MuiDataGrid-cellContent{
  direction: ltr;
}
.red-bold{
    color: red;
    font-weight: bold;
  }
.react-datepicker-wrapper input{
    padding: 10.5px 14px;
    border-radius: 4px;
    border: 1px solid grey;
}
.details-rows strong{
    margin-left: 5px;
}
.edit-line-wrapper .field-wrapper{
    margin-bottom: 10px;
}
.edit-line-wrapper .field-wrapper strong{
    margin-left: 10px   ;
}
.day-wrapper{
    margin-bottom: 20px;
}
.day-title{
    margin-bottom: 10px;
    cursor: pointer;
}
.day-title div{
    margin-left: 5px;
}
.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    height: 1px;
    width: 1px;
  }
  
  .arrow-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  .arrow-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .arrow.white{
    border-color: white;
  }

  div[data-field="view_additional_services"] .arrow{
    margin-right: 5px;
  }
  div[data-field="view_additional_services"] .arrow-down{
    margin-top: -7px;
  }
  .day-name{
    width: 100px;
  }
  .day-wrapper select{
    width: 100%;
    height: 30px;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .watch-status-wrapper{
    margin-bottom: 20px;
  }
  .watch-status-wrapper .title{
    margin-left: 10px;
  }
  .user-account-wrapper{
    max-width: 1000px;
    margin: auto;
  }
  .user-account-wrapper .label{
    width: 200px;
  }
  .user-account-wrapper .label.intend{
    width: 157px;
    margin-right: 10px;
  }
  .user-account-wrapper .label.main,
  .user-account-wrapper .block-list .field-wrapper{
    margin-bottom: 10px;
  }
  .excluded-numbers-wrapper .title{
    margin-bottom: 10px;
  }
  .enter-numbers-wrapper button{
    margin: 0 10px;
    width: 90px;
  }
  .chips div{
    direction: ltr;
  }
  .chips{
    margin-bottom: 15px;
  }
  .watch-status-wrapper,
  .days-wrapper,
  .removal,
  .excluded-numbers-wrapper{
    border: 1px solid #dfdede;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .removal .title{
    margin-bottom: 10px;
  }
  .removal button{
    background-color: red;
  }
  .additional-services-menu{
    position: absolute;
    width: 137px;
    background-color: black;
    border-radius: 5px;
    display: block;
  }
  .additional-services-menu.hidden{
    display: none;
  }
  .strong{
    font-weight: bold;
  }
  .sign-up-to-service .title{
    margin: 10px 0;
  }
  .consent .content{
    display: flex;
    align-items: center;
  }
  .consent{
    margin-bottom: 10px;
  }
  .sign-up-to-service .password{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }
  .sign-up-to-service .password .wrapper{
    margin-left: 10px;
  }
  .password .wrapper.field{
    margin-top: 10px;
  }
  .sign-up-to-service button.agree{
    margin-bottom: 10px;
  }
  .sign-up-to-service .removal{
    margin-bottom: 10px;
  }
  .sign-up-to-service.anti-spam .terms .description div{
    margin-bottom: 10px;
  }
  .small{
    font-size: 12px;
  }
  .error{
    font-size: 12px;
    color: red;
  }
  .service .status{
    display: flex;
    border: 1px solid #dfdede;
    padding: 10px;
    align-items: center;
    margin-bottom: 20px;
  }
  .service .status .title{
    margin-left: 10px;
  }
  .service .password,
  .service .setting-wrapper{
    border: 1px solid #dfdede;
    padding: 10px;
    margin-bottom: 20px;
  }
  .setting-wrapper .content{
    margin: 10px 0;
  }
  .setting-wrapper .note{
    font-size: 12px;
    margin-bottom: 10px;
  }
  .setting-wrapper textarea.numbers{
    width: 203px;
    height: 90px;
  }
  .more-info{
    font-size: 16px;
    border-bottom: 1px dashed;
    width: fit-content;
    cursor: pointer;
  }
  .red{
    color: red;
  }
  .lines-management .MuiDataGrid-footerContainer .MuiTablePagination-actions{
    direction: ltr;
  }
  .service .setting-wrapper .item{
    margin-bottom: 20px;
  }
  .service button.agree{
    margin-bottom: 20px;
  }
  .recording{
    width: 30px;
    cursor: pointer;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 34px;
    height: 34px;
    margin: 24px;
    border: 5px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
@media screen and (max-width: 1100px) {
    .content-wrapper.line-details{
        display: block;
        
    }
    .content-wrapper.line-details .line-detail-wrrapper{
        width: 100%;
    }
}
@media screen and (max-width: 400px) {
    div.content-wrapper .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
        overflow: scroll;
    }

    div.content-wrapper .MuiDataGrid-cellContent{
        overflow: scroll;
    }
    div.content-wrapper .MuiDataGrid-columnHeaders{
        overflow: scroll;
    }
}